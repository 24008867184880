import React from 'react';
import './styles/RowsPerPageDropdown.css';

export default function RowsPerPageDropdown({ value, onChange }) {
    return (
        <div className="dropdown">
            <button className="dropbtn">
                {value} <span className="arrow-down"></span>
            </button>
            <div className="dropdown-content">
                {[10, 20, 50].map((Number) => (
                    <button
                        key={Number}
                        onClick={() => onChange(Number)}
                        className="dropdown-item">
                        {Number}
                    </button>
                ))}
            </div>
        </div>
    );
}