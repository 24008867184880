import React from 'react';
import './styles/Header.css';
import { Link } from 'react-router-dom';
import LogOut from './LogOut.jsx';

export default function WatcherHeader() {
    return (
        <nav className="navbar">
            <div className="navbar-links-container">
                <ul className="navbar-links">
                    <li style={{ marginLeft: "0" }}><Link to="/users">Users</Link></li>
                </ul>
            </div>
            <LogOut />
        </nav>
    );
}