import React from 'react';

export default function AuctionStatusIndicator({ quantity, deletedQuantity }) {
    const activeQuantity = quantity - deletedQuantity;
    const total = quantity;

    const greenWidth = total ? (activeQuantity / total) * 100 : 0;
    const redWidth = total ? (deletedQuantity / total) * 100 : 0;

    return (
        <div style={{ display: 'flex', width: '100%', height: '100%', position: 'relative', backgroundColor: 'grey' }}>
            {activeQuantity > 0 && (
                <div style={{ width: `${greenWidth}%`, backgroundColor: '#66cdaa', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', height: '100%' }}>
                    {activeQuantity}
                </div>
            )}
            {deletedQuantity > 0 && (
                <div style={{ width: `${redWidth}%`, backgroundColor: '#f76262', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', height: '100%' }}>
                    {deletedQuantity}
                </div>
            )}
        </div>
    );
};