import React from 'react';
import './styles/ErrorDialog.css';

export default function ErrorDialog(props) {
    if (!props.open) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-dialog">
                <div className="modal-header">
                    <p>Error</p>
                </div>
                <div className="dialog-modal-content">
                    <p>{props.errorMessage}</p>
                </div>
                <div className="modal-footer">
                    <button className="ActionButton DeleteButton" onClick={props.handleClose}>Close</button>
                </div>
            </div>
        </div>
    );
}