import React from 'react';
import { Button } from 'react-bootstrap';
import AuctionStatusIndicator from './AuctionStatusIndicator.jsx';
import DateRange from './DateRange.jsx';
import openUrlIcon from './assets/external-link.png';

export default function AuctionTableRow({
    item,
    handleTitleClick,
    auctionsStyles,
    handleDelete,
    handleRecover,
    handlePublish,
    handleSkipEstimates,
    handleSkipPrices,
    handleEdit
}) {
    return (
        <tr key={item.id}>
            <td className="table-cell" style={{ textAlign: 'center', width: '40px' }}>{item.sourceId}</td>
            <td className="table-cell" style={{ textAlign: 'center', width: '100px' }}>{item.id}</td>
            <td className="table-cell name-cell">
                <span onClick={() => handleTitleClick(item.id)} style={auctionsStyles[item.id] || { color: 'black' }}>{item.title}</span>
            </td>
            <td className="table-cell" style={{ textAlign: 'center' }}>
                <DateRange startDate={item.date} endDate={item.dateUntil} />
            </td>
            <td className="table-cell" style={{ padding: 0, textAlign: 'center', width: '100px' }}>
                {item.isPublished || item.isDeleted
                    ? 'Moderated'
                    : auctionsStyles[item.id]
                        ? 'InModeration'
                        : 'Await Moderation'
                }
            </td>
            <td
                className="table-cell"
                style={{ textAlign: 'center', padding: '0', width: '100px' }}>
                {item.isDeleted
                    ? 'Deleted'
                    : item.isPublished
                        ? 'Published'
                        : 'Pending'
                }
            </td>
            <td
                className="table-cell"
                style=
                {{
                    textAlign: 'center',
                    padding: "7px",
                    width: "250px",
                    height: "0px"
                }}>
                <AuctionStatusIndicator
                    quantity={item.jewelriesQuantity}
                    deletedQuantity={item.jewelriesQuantityDeleted}
                />
            </td>
            <td className="table-cell" style={{ textAlign: 'center', width: '50px' }}>
                {item.jewelriesQuantity > 0
                    ? `${((item.jewelriesQuantity - (item.jewelriesQuantityDeleted || 0)) / item.jewelriesQuantity * 100).toFixed(2)}%`
                    : '0.00%'
                }
            </td>
            <td className="table-cell" style={{ textAlign: 'center', width: '50px' }}>{item.jewelriesQuantity}</td>
            <td className="table-cell" style={{ padding: '0', width: '12vw' }}>
                <div className="ActionButtonsContainer">
                    <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="UrlButton">
                        <img
                            className="UrlButton"
                            src={openUrlIcon}
                            alt="Open" />
                    </a>
                    <Button
                        variant="contained"
                        className="ActionButton PublishButton"
                        onClick={() => handlePublish(item.id)}>
                        Publish
                    </Button>
                    <Button
                        variant="contained"
                        className="ActionButton SaveAuctionButton"
                        onClick={() => handleEdit(item.id)}>
                        Edit
                    </Button>
                    <Button
                        variant="contained"
                        className="ActionButton DeleteButton"
                        onClick={() => handleDelete(item)}>
                        Delete
                    </Button>
                    <Button
                        variant="contained"
                        className="ActionButton RecoverButton"
                        onClick={() => handleRecover(item.id)}>
                        Recover
                    </Button>
                    {item.isSkippingEstimates ? (
                        <div
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                padding: '5px 10px',
                                margin: '4px 2px',
                                fontSize: '14px',
                                borderRadius: '5px',
                                textAlign: 'center'
                            }}>
                            Skipped Estimates
                        </div>
                    ) : (
                        <Button
                            variant="contained"
                                className="ActionButton PublishButton"
                            onClick={() => handleSkipEstimates(item.id)}>
                            Skip Estimates
                        </Button>
                    )}
                    {item.isSkippingPrices ? (
                        <div
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                padding: '5px 10px',
                                margin: '4px 2px',
                                fontSize: '14px',
                                borderRadius: '5px',
                                textAlign: 'center'
                            }}>
                            Skipped Prices
                        </div>
                    ) : (
                        <Button
                            variant="contained"
                            className="ActionButton PublishButton"
                                onClick={() => handleSkipPrices(item.id)}>
                                Skip Prices
                        </Button>
                    )}
                </div>
            </td>
        </tr>
    );
}