import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setTasks } from '../Redux/Actions/Action';

const TaskList = ({ token }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.currentUser);
    const tasks = useSelector((state) => state.tasks);

    useEffect(() => {
        if (currentUser) {
            axios.get(`${process.env.REACT_APP_BASE_URL}/admin/tasks`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log('response');
                    dispatch(setTasks(response.data));
                })
                .catch(error => {
                    console.log('error');
                    if (error.response && error.response.status === 403) {
                        navigate('/sources');
                    } else {
                        console.error('Error fetching tasks:', error);
                    }
                });
        }
    }, [dispatch, token, currentUser, navigate]);

    return (
        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Assigned To</th>
                </tr>
            </thead>
            <tbody>
                {tasks.map((task) => (
                    <tr key={task.sourceId}>
                        <td>{task.sourceId}</td>
                        <td>{task.assignedTo}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default TaskList;