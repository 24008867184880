import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser, setUsers } from '../Redux/Actions/Action';

const UserSwitcher = ({ token }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.currentUser);
    const users = useSelector((state) => state.users);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/admin/tasks/emails`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                dispatch(setUsers(response.data));
                if (response.data.length > 0) {
                    dispatch(setUser(response.data[0]));
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    navigate('/sources');
                } else {
                    console.error('Error fetching users:', error);
                }
            });
    }, [dispatch, token, navigate]);

    const handleSwitchUser = (user) => {
        dispatch(setUser(user));
    };

    return (
        <div>
            {users.map((user) => (
                <button
                    key={user}
                    style={{
                        backgroundColor: currentUser === user ? 'lightblue' : 'white',
                        border: '1px solid black',
                        margin: '0 5px',
                        padding: '5px 10px',
                    }}
                    onClick={() => handleSwitchUser(user)}>
                    {user}
                </button>
            ))}
        </div>
    );
};

export default UserSwitcher;