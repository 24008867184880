import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ListViewComponent from '../Moderation/ListViewComponent.jsx';
import './styles/AddNewItemDialog.css';

export default function AddNewItemDialog(props) {
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        let accessToken = localStorage.getItem('tokenKey');
        if (!accessToken) {
            navigate('/login');
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/admin/${props.endpoint}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        navigate('/login');
                        return {};
                    }
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setItems(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    }, [props.endpoint, navigate]);

    const getTitle = (endpoint) => {
        const titles = {
            'manufacturers': 'Manufacturer',
            'gemstones': 'Gemstone',
            'claspMaterials': 'Clasp Material',
            'claspOrBuckles': 'Clasp or buckle',
            'strongMaterials': 'Material',
            'weaves': 'Weave',
            'motifs': 'Motif',
            'styles': 'Style',
            'conditions': 'Condition'
        };

        return titles[endpoint] || 'Add New Item';
    };

    return (
        <dialog className="add-new-item-dialog" open={props.isOpen}>
            <div className="modal-content">
                <button className="close-button" onClick={props.onClose}>&times;</button>
                <span className="modal-title">{getTitle(props.endpoint)}</span>
                {items.length > 0 && (
                    <ListViewComponent items={items} endpoint={props.endpoint} />
                )}
            </div>
        </dialog>
    );
}