import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Pagination from '../Source/Pagination.jsx';
import Header from '../Header/Header.jsx';
import WatcherHeader from '../Header/WatcherHeader.jsx';

export default function Users() {
    const [allUsers, setAllUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();
    const roleId = parseInt(localStorage.getItem("roleId"), 10);

    const fetchUsers = async (url) => {
        setIsLoaded(false);
        const accessToken = localStorage.getItem('tokenKey');
        if (!accessToken) {
            navigate('/login');
            return;
        }
        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            setAllUsers(response.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                navigate('/login');
            }
        } finally {
            setIsLoaded(true);
        }
    };

    useEffect(() => {
        let url = `${process.env.REACT_APP_BASE_URL}/admin/users`;

        fetchUsers(url);

    }, [navigate]);

    const handleOpenUser = (userEmail, userId) => {
        const cleanedUserEmail = userEmail.split('@')[0];
        navigate(`/users/${cleanedUserEmail}/subscription`, {
            state: { userId: userId }
        });
    };

    const handleChangeRowsPerPage = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(0);
    };

    return (
        <>
            {roleId === 4 ? <WatcherHeader /> : <Header />}
            <div className="SourceTable">
                <table>
                    <thead>
                        <tr>
                            <th className="title" style={{ backgroundColor: '#fafafa' }} >Email</th>
                            <th className="dark-header" style={{ width: '200px', textAlign: 'center' }}>Created Date</th>
                            <th className="dark-header" style={{ width: '200px', textAlign: 'center' }}>Is Email Confirmed</th>
                            <th className="dark-header" style={{ width: '100px', textAlign: 'center' }}>Subscriptions</th>
                        </tr>
                    </thead>
                    {isLoaded ? (
                        <tbody>
                            {allUsers.slice(
                                currentPage * rowsPerPage,
                                (currentPage + 1) * rowsPerPage
                            ).map((user) => (
                                <tr key={user.id}>
                                    <td className="title">
                                        <span onClick={() => handleOpenUser(user.email, user.id)}>{user.email}</span>
                                    </td>
                                    <td className="url" style={{ textAlign: 'center' }}>{user.createdDate}</td>
                                    <td className="url" style={{ textAlign: 'center' }}>
                                        {user.isEmailConfirmed ? 'Yes' : 'No'}
                                    </td>
                                    <td className="url" style={{ textAlign: 'center' }}>{user.subscriptions}</td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <div>Loading...</div>
                    )}
                </table>

                <Pagination
                    rowsPerPage={rowsPerPage}
                    setPage={setCurrentPage}
                    filteredAuctionsLength={allUsers.length}
                    page={currentPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage} />
            </div>
        </>
    );
}