import React, { useState } from 'react';
import './styles/PhotoSwitcher.css';

export default function PhotoSwitcher({ photos }) {
    const validPhotos = photos && photos.length ? photos.filter(photo => photo.trim() !== '') : [];
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

    const handleNext = () => {
        setCurrentPhotoIndex(prevIndex => (prevIndex + 1) % validPhotos.length);
    };

    const handlePrev = () => {
        setCurrentPhotoIndex(prevIndex => (prevIndex - 1 + validPhotos.length) % validPhotos.length);
    };

    if (!validPhotos.length) {
        return <div>No photos available</div>;
    }

    return (
        <div className="photo-switcher">
            <img src={validPhotos[currentPhotoIndex]} alt=''/>
            <div className="button-wrapper">
                <button onClick={handlePrev}>&lt;</button>
                <button onClick={handleNext}>&gt;</button>
            </div>
        </div>
    );
};