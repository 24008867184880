import React from 'react';

export default function RecoverAuctionDialog(props) {
    if (!props.showDialog) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-dialog">
                <div className="modal-header">
                    <p>Recover Auction</p>
                </div>
                <div className="dialog-modal-content">
                    <p>
                        Are you sure you want to recover the auction with ID {props.item.id} and title {props.item.title}?
                    </p>
                </div>
                <div className="modal-footer">
                    <button onClick={props.handleCancel} className="ActionButton DeleteButton">Cancel</button>
                    <button onClick={props.handleConfirmRecover} className="ActionButton PublishButton">Confirm Recover</button>
                </div>
            </div>
        </div>
    );
}