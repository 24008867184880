import React from 'react';
import Select from 'react-select';

const options = [
    { value: 'past', label: 'Past' },
    { value: 'upcoming', label: 'Upcoming' }
];

export default function SelectSourceType({ filter, handleFilterChange }) {
    return (
        <Select
            className="select-source-type"
            value={options.find(option => option.value === filter)}
            onChange={selectedOption => handleFilterChange(selectedOption.value)}
            options={options}/>
    );
}