export default function DateRange({ startDate, endDate }) {
    const start = startDate ? startDate.split('T')[0] : "No start date";
    const end = endDate ? endDate.split('T')[0] : "No end date";
    return (
        <>
            {start}
            <br />
            {end}
        </>
    );

};