import React from 'react';
import Select from 'react-select';

const options = [
    { value: 'Rejected', label: 'Rejected' },
    { value: 'To Check', label: 'To Check' },
    { value: 'To Do', label: 'To Do' },
    { value: 'In Progress', label: 'InProgress' },
    { value: 'Done', label: 'Done' },
];

export default function ChangeJiraTicketStatus({ filter, handleFilterChange }) {
    const customStyles = {
        menuPortal: base => ({
            ...base,
            zIndex: 1001
        })
    };

    return (
        <Select
            className="select-status-type"
            value={options.find(option => option.value === filter)}
            onChange={selectedOption => handleFilterChange(selectedOption.value)}
            options={options}
            styles={customStyles}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
        />
    );
}