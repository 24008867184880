import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from '../Header/Header.jsx';

export default function Subscription() {
    const [allSubscriptions, setAllSubscriptions] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();

    const location = useLocation();
    const { userId } = location.state || {};

    const fetchSubscriptions = async (url) => {
        setIsLoaded(false);
        const accessToken = localStorage.getItem('tokenKey');
        if (!accessToken) {
            navigate('/login');
            return;
        }
        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            setAllSubscriptions(response.data);
        } catch (error) {
            if (error.response.status === 401 || error.response.status === 403) {
                navigate('/login');
            }
        } finally {
            setIsLoaded(true);
        }
    };

    useEffect(() => {
        let url = `${process.env.REACT_APP_BASE_URL}/admin/users/${userId}/subscription`;

        fetchSubscriptions(url);

    }, [navigate]);

    return (
        <>
            <Header />
            <div className="SourceTable">
                <table>
                    <thead>
                        <tr>
                            <th className="dark-header" style={{ width: '200px', textAlign: 'center' }}>Title</th>
                            <th className="dark-header" style={{ width: '100px', textAlign: 'center' }}>Price</th>
                            <th className="dark-header" style={{ width: '100px', textAlign: 'center' }}>Start Date</th>
                            <th className="dark-header" style={{ width: '100px', textAlign: 'center' }}>End Date</th>
                        </tr>
                    </thead>
                    {isLoaded ? (
                        <tbody>
                            {allSubscriptions.map((subscription) => (
                                <tr key={subscription.id}>
                                    <td className="url" style={{ textAlign: 'center' }}>{subscription.title}</td>
                                    <td className="url" style={{ textAlign: 'center' }}>{subscription.price}$</td>
                                    <td className="url" style={{ textAlign: 'center' }}>{subscription.startDate}</td>
                                    <td className="url" style={{ textAlign: 'center' }}>{subscription.endDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <div>Loading...</div>
                    )}
                </table>
            </div>
        </>
    );
}