import React, { useState } from 'react';
import './styles/Pagination.css';

export default function Pagination({ currentPage, setPage, totalPages, }) {
    const [inputPage, setInputPage] = useState('');

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleGoToPage();
        }
    };

    const handleGoToPage = () => {
        const page = Number(inputPage);
        if (!isNaN(page) && page >= 1 && page <= totalPages) {
            setPage(page);
            setInputPage('');
        }
    };

    if (isNaN(totalPages)) {
        totalPages = 1;
    }

    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
    }

    const pageNumbers = pages.slice(
        Math.max(0, currentPage - 3),
        Math.min(totalPages, currentPage + 2)
    );

    if (isNaN(currentPage) || isNaN(totalPages) || totalPages <= 0) {
        return null;
    }

    return (
        <div className="pagination">
            <button className="choose-page-button1" onClick={() => currentPage > 1 && setPage(currentPage - 1)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 8 8" fill="none">
                    <path d="M3.62351 5.57L1.22551 2.829C0.942505 2.5065 1.17251 2 1.60201 2H6.39801C6.49413 1.99992 6.58824 2.02754 6.66906 2.07957C6.74989 2.1316 6.814 2.20582 6.85373 2.29335C6.89346 2.38087 6.90711 2.478 6.89306 2.57309C6.87901 2.66818 6.83785 2.7572 6.77451 2.8295L4.37651 5.5695C4.32957 5.6232 4.2717 5.66625 4.20676 5.69574C4.14182 5.72523 4.07133 5.74049 4.00001 5.74049C3.92868 5.74049 3.85819 5.72523 3.79325 5.69574C3.72831 5.66625 3.67044 5.6232 3.62351 5.5695V5.57Z" fill="#9E9E9E" />
                </svg>
            </button>

            {pageNumbers.map((page) => (
                <button
                    key={page}
                    disabled={currentPage === page}
                    className={`page-button ${currentPage === page ? 'active' : ''}`}
                    onClick={() => setPage(page)}
                >
                    {page}
                </button>
            ))}

            {currentPage < totalPages && (
                <>
                    {currentPage < totalPages - 2 && (
                        <>
                            {currentPage < totalPages - 2 && <span className="pagination-ellipsis">...</span>}
                            <button className="page-button" onClick={() => setPage(totalPages)}>
                                {totalPages}
                            </button>
                        </>
                    )}
                </>
            )}

            <button className="choose-page-button2" onClick={() => currentPage < totalPages && setPage(currentPage + 1)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 8 8" fill="none">
                    <path d="M3.62351 5.57L1.22551 2.829C0.942505 2.5065 1.17251 2 1.60201 2H6.39801C6.49413 1.99992 6.58824 2.02754 6.66906 2.07957C6.74989 2.1316 6.814 2.20582 6.85373 2.29335C6.89346 2.38087 6.90711 2.478 6.89306 2.57309C6.87901 2.66818 6.83785 2.7572 6.77451 2.8295L4.37651 5.5695C4.32957 5.6232 4.2717 5.66625 4.20676 5.69574C4.14182 5.72523 4.07133 5.74049 4.00001 5.74049C3.92868 5.74049 3.85819 5.72523 3.79325 5.69574C3.72831 5.66625 3.67044 5.6232 3.62351 5.5695V5.57Z" fill="#9E9E9E" />
                </svg>
            </button>

            <div className="go-to-page">
                <div>Go to</div>
                <input
                    value={inputPage}
                    onChange={(e) => setInputPage(e.target.value)}
                    className="goto-page-input"
                    onKeyPress={handleKeyPress}
                    min={1}
                    max={totalPages}
                />
                <div>page</div>
            </div>
        </div>
    );
}