export const ADD_TASK = 'ADD_TASK';
export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';
export const SET_TASKS = 'SET_TASKS';

export const addTask = (task) => ({
    type: ADD_TASK,
    payload: task,
});

export const setUser = (user) => ({
    type: SET_USER,
    payload: user,
});

export const setUsers = (users) => ({
    type: SET_USERS,
    payload: users,
});

export const setTasks = (tasks) => ({
    type: SET_TASKS,
    payload: tasks,
});