import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DeleteAuctionDialog from '../Dialogs/DeleteAuctionDialog.jsx';
import PublishAuctionDialog from '../Dialogs/PublishAuctionDialog.jsx';
import RecoverAuctionDialog from '../Dialogs/RecoverAuctionDialog.jsx';
import EditAuctionDialog from '../Dialogs/EditAuctionDialog.jsx';
import '../Jewelry/Jewelries.jsx';
import './styles/Auctions.css';
import Header from '../Header/Header';
import Pagination from '../Source/Pagination.jsx';
import AuctionTable from './AuctionTable.jsx';
import EventStatus from './EventStatus.jsx';

async function fetchAuctionsFixes(sourceId, accessToken, setAuctionsWithFixes) {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/fixes`,
            {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const fixes = await response.json();
        setAuctionsWithFixes(fixes);
    } catch (error) {
        console.error('Error fetching jewelry fixes:', error);
    }
}

export default function Auctions() {
    const [auctions, setAuctions] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const accessToken = localStorage.getItem('tokenKey');
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [auctionsWithFixes, setAuctionsWithFixes] = useState([]);

    const [isDeletedLotsChecked, setIsDeletedLotsChecked] = useState(false);

    const [itemToPublish, setItemToPublish] = useState(null);
    const [itemToRecover, setItemToRecover] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);

    const [showPublishDialog, setShowPublishDialog] = useState(false);
    const [showRecoverDialog, setShowRecoverDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const sourceId = localStorage.getItem("sourceId");

    const handleDeletedLotsChange = (checked) => {
        setIsDeletedLotsChecked(checked);
        if (checked) {
            //fetchDeletedLots();
        } else {
            fetchAuctions();
        }
    };

    const fetchAuctions = () => {
        let accessToken = localStorage.getItem('tokenKey');
        if (!accessToken) {
            navigate('/login');
        }
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        navigate('/login');
                        return {};
                    }
                    throw new Error('Network response was not ok');
                }

                return response.json();
            })
            .then(data => {
                if (Object.keys(data).length !== 0) {
                    setAuctions(data);
                    setIsLoaded(true);
                    fetchAuctionsFixes(sourceId, accessToken, setAuctionsWithFixes);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        fetchAuctions();
    // eslint-disable-next-line
    }, [navigate, sourceId]);

    const calculateStylesForAuctions = auctions => {
        return auctions.reduce((acc, auction) => {
            acc[auction.id] = {
                color: auction.isFixed === true ? '#66FF00' : auction.isFixed === false ? '#FF033E' : 'inherit',
            };
            return acc;
        }, {});
    };

    const auctionsStyles = calculateStylesForAuctions(auctionsWithFixes);

    const handleTitleClick = (id) => {
        const filteredAuctions = auctions.filter(auction => auction.jewelriesQuantity !== 0);
        localStorage.setItem("auctionId", id);
        navigate('/jewelries', { state: { filteredAuctions, auctionsStyles } });
    };

    const handleCancel = () => {
        setShowDialog(false);
    };

    const handleDelete = (item) => {
        setSelectedItem(item);
        setShowDialog(true);
    };

    const handlePublish = (id) => {
        const itemToPublish = auctions.find(auction => auction.id === id);
        if (itemToPublish) {
            setItemToPublish(itemToPublish);
            setShowPublishDialog(true);
        } else {
            console.error('Item not found for publishing');
        }
    };

    const handleSkipEstimates = (id) => {
        const itemToSkipEstimates = auctions.find(auction => auction.id === id);
        if (itemToSkipEstimates) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            const token = localStorage.getItem('tokenKey');

            const data = {
                sourceId: itemToSkipEstimates.sourceId,
                auctionId: itemToSkipEstimates.id,
                IsSkippingEstimates: !itemToSkipEstimates.IsSkippingEstimates
            };

            axios.patch(`${process.env.REACT_APP_BASE_URL}/admin/sources/auctions/skip/estimates`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            })
                .then(response => {
                    console.log('Response:', response.data);
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled', error.message);
                    } else {
                        console.error('Error:', error);
                    }
                });
        } else {
            console.error('Item not found for skipping estimates');
        }
    };

    const handleSkipPrices = (id) => {
        const itemToSkipPrices = auctions.find(auction => auction.id === id);
        if (itemToSkipPrices) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();

            const token = localStorage.getItem('tokenKey');

            const data = {
                sourceId: itemToSkipPrices.sourceId,
                auctionId: itemToSkipPrices.id,
                IsSkippingPrices: !itemToSkipPrices.IsSkippingPrices
            };

            axios.patch(`${process.env.REACT_APP_BASE_URL}/admin/sources/auctions/skip/prices`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            })
                .then(response => {
                    console.log('Response:', response.data);
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled', error.message);
                    } else {
                        console.error('Error:', error);
                    }
                });
        } else {
            console.error('Item not found for publishing');
        }
    };

    const handleRecover = (id) => {
        const itemToRecover = auctions.find(auction => auction.id === id);
        if (itemToRecover) {
            setItemToRecover(itemToRecover);
            setShowRecoverDialog(true);
        } else {
            console.error('Item not found for recovering');
        }
    };

    const handleEdit = (id) => {
        const itemToEdit = auctions.find(auction => auction.id === id);
        if (itemToEdit) {
            setItemToEdit(itemToEdit);
            setShowEditDialog(true);
        } else {
            console.error('Item not found for editing');
        }
    };

    const handleConfirmPublish = (item) => {
        setShowPublishDialog(false);
        setIsPublishing(true);
        fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions/${item.id}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        })
            .then(() => {
                setAuctions(auctions.map(auction => {
                    if (auction.id === item.id) {
                        return {
                            ...auction,
                            isModerated: true,
                            isPublished: true
                        };
                    }
                    return auction;
                }));
                setIsPublishing(false);
            })
            .catch(error => {
                console.error('Error making publish request:', error);
                setIsPublishing(false);
            });
    };

    const handleConfirmRecover = (item) => {
        setShowRecoverDialog(false);
        fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions/recover/${item.id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        })
            .then(() => {
                setAuctions(auctions.map(auction => {
                    if (auction.id === item.id) {
                        return {
                            ...auction,
                            isDeleted: false,
                            isPublished: false
                        };
                    }
                    return auction;
                }));
            })
            .catch(error => {
                console.error('Error making recover request:', error);
            });
    };

    const handleCancelPublish = () => {
        setShowPublishDialog(false);
    };

    const handleCancelRecover = () => {
        setShowRecoverDialog(false);
    };

    const handleCancelEdit = () => {
        setItemToEdit(null);
        setShowEditDialog(false);
    };

    const handleConfirmDelete = (item) => {
        setShowDialog(false);
        fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions/${item.id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        })
            .then(() => {
                setAuctions(auctions.map(auction => {
                    if (auction.id === item.id) {
                        return {
                            ...auction,
                            isModerated: true,
                            isDeleted: true
                        };
                    }
                    return auction;
                }));
            })
            .catch(error => {
                console.error('Error making delete request:', error);
            });
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <Header />
            <div className="auctions-page-container">
                {isLoaded && !isPublishing ? (
                    <>
                        <EventStatus
                            auctions={auctions}
                            setAuctions={setAuctions}
                            auctionsStyles={auctionsStyles}
                            onDeletedLotsChange={handleDeletedLotsChange}
                        />
                        <div className="auctions-data-container">
                            <AuctionTable
                                filteredAuctions={auctions}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handleTitleClick={handleTitleClick}
                                auctionsStyles={auctionsStyles}
                                handleDelete={handleDelete}
                                handlePublish={handlePublish}
                                handleRecover={handleRecover}
                                handleSkipEstimates={handleSkipEstimates}
                                handleSkipPrices={handleSkipPrices}
                                handleEdit={handleEdit}
                            />
                        </div>
                        <Pagination
                            rowsPerPage={rowsPerPage}
                            setPage={setPage}
                            filteredAuctionsLength={auctions.length}
                            page={page}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </>
                ) : (
                    <div className="loading-container">
                        <div className="spinner"></div>
                    </div>
                )}
                {selectedItem && (
                    <DeleteAuctionDialog
                        showDialog={showDialog}
                        handleCancel={handleCancel}
                        handleConfirmDelete={() => handleConfirmDelete(selectedItem)}
                        item={selectedItem}
                    />
                )}
                {itemToPublish && (
                    <PublishAuctionDialog
                        showDialog={showPublishDialog}
                        handleCancel={handleCancelPublish}
                        handleConfirmPublish={() => handleConfirmPublish(itemToPublish)}
                        item={itemToPublish}
                    />
                )}
                {itemToRecover && (
                    <RecoverAuctionDialog
                        showDialog={showRecoverDialog}
                        handleCancel={handleCancelRecover}
                        handleConfirmRecover={() => handleConfirmRecover(itemToRecover)}
                        item={itemToRecover}
                    />
                )}
                {itemToEdit && (
                    <EditAuctionDialog
                        key={itemToEdit.id}
                        showDialog={showEditDialog}
                        handleCancel={handleCancelEdit}
                        sourceId={itemToEdit.sourceId}
                        id={itemToEdit.id}
                        setShowEditDialog={setShowEditDialog}
                    />
                )}
            </div>
        </>
    );
}