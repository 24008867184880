import { ADD_TASK, SET_USER, SET_USERS, SET_TASKS } from '../Actions/Action';

const initialState = {
    tasks: [],
    currentUser: '',
    users: []
};

export const taskReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TASK:
            return {
                ...state,
                tasks: [...state.tasks, action.payload],
            };
        case SET_USER:
            return {
                ...state,
                currentUser: action.payload,
            };
        case SET_USERS:
            return {
                ...state,
                users: action.payload,
            };
        case SET_TASKS:
            return {
                ...state,
                tasks: action.payload,
            };
        default:
            return state;
    }
};