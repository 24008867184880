import TaskForm from './TaskForm';
import TaskList from './TaskList';
import UserSwitcher from './UserSwitcher';
import Header from '../Header/Header.jsx';

export default function AdmivView() {
    const token = localStorage.getItem("tokenKey");

    return (
        <>
            <Header />
            <UserSwitcher token={token} />
            <TaskForm token={token} />
            <TaskList token={token} />
        </>
    )
}