import React, { useEffect, useMemo, useCallback } from 'react';
import Select from 'react-select';
import AuctionHeader from './AuctionHeader.jsx';
import PhotoSwitcher from './PhotoSwitcher.jsx';
import './styles/AuctionInfoContainer.css';

export default function AuctionInfoContainer({
    items,
    countItems,
    auctions,
    selectedAuction,
    handleAuctionChange,
    handleJewelryUrlClick,
    handleLotChange,
    selectedLot,
    setSelectedLot,
    currentItemIndex,
    changeItem,
    detailItem,
    setDetailItem,
    lotsWithFixes,
    auctionsWithFixes,
    isFixed
}) {
    const customStyles = useMemo(() => ({
        option: (provided, { data }) => {
            const lot = lotsWithFixes.find(lot => lot.id === data.value);
            const color = lot?.isFixed ? '#66FF00' : lot?.isFixed === false ? '#FF033E' : 'black';
            return {
                ...provided,
                color: color
            };
        },
        singleValue: (provided) => {
            const color = isFixed ? '#66FF00' : isFixed === false ? '#FF033E' : 'black';
            return {
                ...provided,
                color: color
            };
        }
    }), [lotsWithFixes, isFixed]);

    useEffect(() => {
        if (!selectedLot && items.length > 0) {
            setSelectedLot({ value: items[0].id, label: items[0].id.toString() });
        }
    }, [items, selectedLot, setSelectedLot]);

    const lotOptions = useMemo(() => items.map(item => ({
        value: item.id.toString(),
        label: item.id.toString(),
    })), [items]);

    const onLotSelectedChange = useCallback((selectedOption) => {
        setSelectedLot(selectedOption);
        handleLotChange(selectedOption);
    }, [handleLotChange, setSelectedLot]);

    const handleEstimateChange = (event) => {
        setDetailItem(prevDetailItem => ({
            ...prevDetailItem,
            estimatePriceTo: event.target.value
        }));
    };

    const handleEstimateFromChange = (event) => {
        setDetailItem(prevDetailItem => ({
            ...prevDetailItem,
            estimatePriceFrom: event.target.value
        }));
    };

    const handlePriceChange = (event) => {
        setDetailItem(prevDetailItem => ({
            ...prevDetailItem,
            price: event.target.value
        }));
    };

    return (
        <div className="auction-container">
            <AuctionHeader
                auctions={auctions}
                id={selectedAuction.id}
                date={selectedAuction.date}
                dateUntil={selectedAuction.dateUntil}
                handleAuctionChange={handleAuctionChange}
                auctionsWithFixes={auctionsWithFixes}/>
            {items.length > 0 ? (
                <div className="auction-details">
                    <div className="detail-item">
                        <span className="detail-label">Source Id:</span>
                        <span className="detail-value">{selectedAuction.sourceId}</span>
                    </div>
                    <div className="detail-item">
                        <span className="detail-label">Auction Id:</span>
                        <span className="detail-value adjustable-text-size">{selectedAuction.id}</span>
                    </div>
                    <div className="detail-item">
                        <span className="detail-label">Lot No.:</span>
                        <Select
                            value={selectedLot}
                            onChange={onLotSelectedChange}
                            options={lotOptions}
                            classNamePrefix="select"
                            styles={customStyles}/>
                    </div>
                    <div className="detail-item">
                        <span className="detail-label">Position:</span>
                        <span className="detail-value">{currentItemIndex + 1}/{countItems}</span>
                    </div>
                    <div className="detail-item">
                        <span className="detail-label">Min. Estimate:</span>
                        <input
                            type="number"
                            className="detail-value"
                            value={detailItem.estimatePriceFrom || 0}
                            onChange={handleEstimateFromChange}
                        />
                    </div>
                    <div className="detail-item">
                        <span className="detail-label">Max. Estimate:</span>
                        <input
                            type="number"
                            className="detail-value"
                            value={detailItem.estimatePriceTo || 0}
                            onChange={handleEstimateChange}
                        />
                    </div>
                    <div className="detail-item">
                        <span className="detail-label">Price:</span>
                        <input
                            type="number"
                            className="detail-value"
                            value={detailItem.price || 0}
                            onChange={handlePriceChange}
                        />
                    </div>
                    <div className="detail-item">
                        <span className="detail-label">Currency:</span>
                        <span className="detail-value">{detailItem.currency}</span>
                    </div>
                </div>
            ) : (
                <div className="auction-details-only">
                    <div className="detail-item">
                        <span className="detail-label">Source Id:</span>
                        <span className="detail-value">{selectedAuction.sourceId}</span>
                    </div>
                    <div className="detail-item">
                        <span className="detail-label">Auction Id:</span>
                        <span className="detail-value">{selectedAuction.id}</span>
                    </div>
                </div>
            )}
            {items.length > 0 && (
                <div className="auction-actions">
                    <button className="open-link-button" onClick={() => handleJewelryUrlClick(detailItem.url)}>Open Link</button>
                    <div className="navigation-buttons">
                        <button className="action-button prev-button" onClick={() => changeItem('prev')}>Prev</button>
                        <button className="action-button next-button" onClick={() => changeItem('next')}>Next</button>
                    </div>
                </div>
            )}
            <PhotoSwitcher photos={(detailItem.photosRaw && detailItem.photosRaw.split(',')) || []} />
        </div>
    );
}