import React, { useState, useEffect } from 'react';
import './styles/EditAuctionDialog.css';

export default function EditAuctionDialog({ sourceId, id, handleCancel, showDialog, setShowEditDialog }) {
    const [editData, setEditData] = useState({
        title: '',
        photosRaw: '',
        date: '',
        url: ''
    });
    const [loading, setLoading] = useState(false);
    const accessToken = localStorage.getItem('tokenKey');

    useEffect(() => {
        if (!showDialog) return;

        const controller = new AbortController();

        const fetchAuctionData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/${sourceId}/auctions/${id}/edit`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    },
                    signal: controller.signal,
                });
                if (!response.ok) throw new Error('Failed to fetch');
                const data = await response.json();
                setEditData({
                    title: data.title || '',
                    photosRaw: data.photosRaw || '',
                    date: data.date || '',
                    url: data.url || ''
                });
            } catch (err) {
                if (err.name !== 'AbortError') {
                    console.error('Fetch error:', err);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchAuctionData();

        return () => {
            controller.abort();
        };
    }, [id, sourceId, showDialog, accessToken]);

    if (!showDialog) {
        return null;
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleConfirmEdit();
    };

    const handleConfirmEdit = () => {
        setShowEditDialog(false);
        const item = {
            sourceId: sourceId,
            id: id,
            title: editData.title,
            photosRaw: editData.photosRaw
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/admin/sources/auction`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            })
            .catch(error => {
                console.error('Error making update request:', error);
            });
    };

    return (
        <div className="modal-overlay">
            <div className="modal-edit-dialog">
                <div className="modal-header">
                    <p>Edit Auction</p>
                </div>
                <form onSubmit={handleSubmit} className="dialog-modal-content">
                    <div className="edit-auciton-left-column">
                        {editData.photosRaw && (
                            <a href={editData.photosRaw} target="_blank" rel="noopener noreferrer">
                                <img src={editData.photosRaw} alt="Auction Item" className="edit-auction-img-preview" />
                            </a>
                        )}
                    </div>
                    <div className="edit-auciton-right-column">
                        <div className="edit-auction-row">
                            <label>Title:</label>
                            <input type="text" name="title" value={editData.title} onChange={handleChange} />
                        </div>
                        <div className="edit-auction-row">
                            <label>Date:</label>
                            <input type="text" name="date" value={editData.date} onChange={handleChange} disabled />
                        </div>
                        <div className="edit-auction-row">
                            <label>Photo URL:</label>
                            <input type="text" name="photosRaw" value={editData.photosRaw} onChange={handleChange} disabled={loading} />
                        </div>
                        <div className="edit-auction-row">
                            <label>URL:</label>
                            <input type="text" name="url" value={editData.url} onChange={handleChange} disabled={loading} />
                            <a href={editData.url} target="_blank" rel="noopener noreferrer" className="UrlButton">Open</a>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={handleCancel} className="ActionButton DeleteButton">Cancel</button>
                            <button type="submit" className="ActionButton SaveAuctionButton">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}