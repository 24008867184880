import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ListViewComponent from '../Moderation/ListViewComponent.jsx';
import '../Dialogs/styles/AddTypesDialog.css';

export default function AddTypesDialog({onClose}) {
    const accessToken = localStorage.getItem('tokenKey');
    const navigate = useNavigate();
    const [necklaces, setNecklaces] = useState([]);
    const [chains, setChains] = useState([]);
    const [rings, setRings] = useState([]);
    const [earrings, setEarrings] = useState([]);
    const [bracelets, setBracelets] = useState([]);
    const [brooches, setBrooches] = useState([]);
    const [cufflinks, setCufflinks] = useState([]);
    const [pendants, setPendants] = useState([]);
    const [bangles, setBangles] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const [tabIndex, setTabIndex] = useState(0);
    const handleSelectTab = (index) => {
        setTabIndex(index);
    };

    useEffect(() => {
        if (!accessToken) {
            navigate('/login');
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        };

        const fetchResource = (url) => {
            return fetch(url, requestOptions)
                .then(response => {
                    if (response.status === 401) {
                        throw new Error('Unauthorized');
                    }
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                });
        };

        Promise.all([
            'types/necklace', 'types/chain', 'types/ring', 'types/earrings', 'types/bracelet',
            'types/brooch', 'types/cufflinks', 'types/pendant', 'types/bangle'
        ].map(endpoint => fetchResource(`${process.env.REACT_APP_BASE_URL}/admin/${endpoint}`)))
            .then(allData => {
                setNecklaces(allData[0]);
                setChains(allData[1]);
                setRings(allData[2]);
                setEarrings(allData[3]);
                setBracelets(allData[4]);
                setBrooches(allData[5]);
                setCufflinks(allData[6]);
                setPendants(allData[7]);
                setBangles(allData[8]);
                setDataLoaded(true);
            })
            .catch(error => {
                if (error.message === "Unauthorized") {
                    navigate('/login');
                } else {
                    console.error('Error fetching data:', error);
                }
            });
    }, [navigate, accessToken]);

    return (
        <>
            <div className="add-types-dialog">
                <button className="close-button" onClick={onClose}>&times;</button>
                {dataLoaded ? (
                    <Tabs selectedIndex={tabIndex} onSelect={handleSelectTab}>
                        <TabList>
                            <Tab>Necklace Types</Tab>
                            <Tab>Chain Types</Tab>
                            <Tab>Ring Types</Tab>
                            <Tab>Earrings Types</Tab>
                            <Tab>Bracelet Types</Tab>
                            <Tab>Brooch Types</Tab>
                            <Tab>Cufflinks Types</Tab>
                            <Tab>Pendant Types</Tab>
                            <Tab>Bangle Types</Tab>
                        </TabList>

                        <TabPanel>
                            <ListViewComponent items={necklaces} endpoint='types/necklace' />
                        </TabPanel>
                        <TabPanel>
                            <ListViewComponent items={chains} endpoint='types/chain' />
                        </TabPanel>
                        <TabPanel>
                            <ListViewComponent items={rings} endpoint='types/ring' />
                        </TabPanel>
                        <TabPanel>
                            <ListViewComponent items={earrings} endpoint='types/earrings' />
                        </TabPanel>
                        <TabPanel>
                            <ListViewComponent items={bracelets} endpoint='types/bracelet' />
                        </TabPanel>
                        <TabPanel>
                            <ListViewComponent items={brooches} endpoint='types/brooch' />
                        </TabPanel>
                        <TabPanel>
                            <ListViewComponent items={cufflinks} endpoint='types/cufflinks' />
                        </TabPanel>
                        <TabPanel>
                            <ListViewComponent items={pendants} endpoint='types/pendant' />
                        </TabPanel>
                        <TabPanel>
                            <ListViewComponent items={bangles} endpoint='types/bangle' />
                        </TabPanel>
                    </Tabs>
                ) : (
                    <div>Loading...</div>
                )}
            </div>
        </>
    );
};