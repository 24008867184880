import React from 'react';
import CreatableSelect from 'react-select/creatable';

export default function GenderDetailItem ({
    label,
    value,
    fixValue,
    onValueChange,
    onFixValueChange
}) {

    const options = [
        { label: 'Men', value: 'Men' },
        { label: 'Women', value: 'Women' },
        { label: 'Unisex', value: 'Unisex' },
        { label: 'Unknown', value: '' },
    ];

    const handleChange = (selectedOption) => {
        onValueChange({
            target: {
                value: selectedOption ? selectedOption.value : '',
            }
        });
    };

    const getOptionByValue = (valueToFind) => {
        return options.find(option => option.value.toLowerCase() === valueToFind.toLowerCase());
    };

    const valueToUse = value ? getOptionByValue(value) : null;

    return (
        <div className="detail-item">
            <span className="detail-label">{label}:</span>
            <CreatableSelect
                className="category-select"
                isClearable
                onChange={handleChange}
                options={options}
                value={valueToUse}
            />
            <span className="jewelry-detail-label" style={{ paddingLeft: '0px', marginLeft: '0px' }}>Fix:</span>
            <div className="fix-input-container">
                <input
                    className="fix-input"
                    style={{
                        color: fixValue === '' ? 'black' :
                            fixValue === value ? 'chartreuse' : 'red',
                        fontWeight: 'bold'
                    }}
                    value={fixValue}
                    onChange={onFixValueChange}
                />
            </div>
        </div>
    );
}