import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorDialog from '../Dialogs/ErrorDialog.jsx';
import LoginForm from './LoginForm.jsx';
import './styles/App.css';

export default function App () {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const [showError, setShowError] = useState(false);
    const error = "Wrong password or user doesn't exist.";

    const handleNameChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/auth/login`, requestOptions);
            if (response.ok) {
                const data = await response.json();

                localStorage.setItem('tokenKey', data.token);
                localStorage.setItem('email', email);
                if (data.roleId === 4) {
                    localStorage.setItem('roleId', data.roleId);
                    navigate('/users');
                } else {
                    navigate('/sources');
                }
            } else {
                setShowError(true);
            }
        } catch (error) {
            console.error(error);
        }
    };


    const handleCloseError = () => {
        setShowError(false);
        setPassword('');
    };

    return (
        <div className="main">
            <div className="login-container">
                <LoginForm email={email} password={password} handleNameChange={handleNameChange} handlePasswordChange={handlePasswordChange} handleSubmit={handleSubmit}/>
                <ErrorDialog open={showError} handleClose={handleCloseError} errorMessage={error}/>
            </div>
        </div>
    );
}