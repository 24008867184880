import React, { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ListViewComponent from './ListViewComponent';
import 'react-tabs/style/react-tabs.css';

export default function TabsComponent(props) {
    const [tabIndex, setTabIndex] = useState(0);

    const handleSelectTab = (index) => {
        setTabIndex(index);
    };

    return (
        <Tabs selectedIndex={tabIndex} onSelect={handleSelectTab}>
            <TabList>
                <Tab>Motif</Tab>
                <Tab>Style</Tab>
                <Tab>Weave</Tab>
                <Tab>Necklace Types</Tab>
                <Tab>Chain Types</Tab>
                <Tab>Ring Types</Tab>
                <Tab>Earrings Types</Tab>
                <Tab>Bracelet Types</Tab>
                <Tab>Brooch Types</Tab>
                <Tab>Cufflinks Types</Tab>
                <Tab>Pendant Types</Tab>
                <Tab>Bangle Types</Tab>
                <Tab>Clasp or buckle</Tab>
                <Tab>Condition</Tab>
                <Tab>Gemstone</Tab>
                <Tab>Weak material</Tab>
                <Tab>Strong material</Tab>
                <Tab>Manufacturer</Tab>
            </TabList>

            {props.dataLoaded ? (
                <><TabPanel>
                    <ListViewComponent items={props.motifs} endpoint='motifs' />
                </TabPanel><TabPanel>
                        <ListViewComponent items={props.styles} endpoint='styles' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.weaves} endpoint='weaves' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.necklaces} endpoint='types/necklace' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.chains} endpoint='types/chain' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.rings} endpoint='types/ring' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.earrings} endpoint='types/earrings' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.bracelets} endpoint='types/bracelet' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.brooches} endpoint='types/brooch' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.cufflinks} endpoint='types/cufflinks' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.pendants} endpoint='types/pendant' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.bangles} endpoint='types/bangle' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.claspOrBuckles} endpoint='claspOrBuckles' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.conditions} endpoint='conditions' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.gemstones} endpoint='gemstones' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.weakMaterials} endpoint='weakMaterials' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.strongMaterials} endpoint='strongMaterials' />
                    </TabPanel><TabPanel>
                        <ListViewComponent items={props.manufacturers} endpoint='manufacturers' />
                    </TabPanel></>
            ) : (
                <div>Loading...</div>
            )}
        </Tabs>
    );
};