import React from 'react';
import './styles/SearchFilter.css';

export default function SearchFilter({ selectedOption, setSelectedOption }) {
    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    return (
        <div className="option-selector">
            <label className="checkbox-container">
                <input
                    type="checkbox"
                    checked={selectedOption === 'Upcoming'}
                    onChange={() => handleOptionChange('Upcoming')}
                />
                <span className="checkmark"></span>
                Upcoming
            </label>
            <label className="checkbox-container">
                <input
                    type="checkbox"
                    checked={selectedOption === 'Past'}
                    onChange={() => handleOptionChange('Past')}
                />
                <span className="checkmark"></span>
                Past
            </label>
        </div>
    );
}