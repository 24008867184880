import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTask } from '../Redux/Actions/Action';

const TaskForm = ({ token }) => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser);
    const [task, setTask] = useState({ sourceId: '', assignedTo: '' });

    const handleChange = (e) => {
        setTask({ ...task, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const taskToSubmit = {
            ...task,
            assignedTo: task.assignedTo || currentUser
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin/task`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(taskToSubmit)
            });

            if (response.ok) {
                dispatch(addTask(taskToSubmit));
                setTask({ sourceId: '', assignedTo: '' });
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error submitting task:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>ID:</label>
                <input
                    type="text"
                    name="sourceId"
                    value={task.sourceId}
                    onChange={handleChange}
                />
            </div>
            <div>
                <label>Assigned To:</label>
                <input
                    type="text"
                    name="assignedTo"
                    value={task.assignedTo}
                    onChange={handleChange}
                />
            </div>
            <button type="submit">Add Task</button>
        </form>
    );
};

export default TaskForm;