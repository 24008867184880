import React from 'react';
import { useEffect } from 'react';

export default function Frame({ item }) {

    useEffect(() => {

    }, [item]);

    return (
        <div className="iframe-sidebar">
            <iframe
                title="jewelry-iframe"
                className="iframe"
                src={item}/>
        </div>
    );
};