import SelectSourceType from './SelectSourceType.jsx';
import SelectJiraTask from './SelectJiraTask.jsx';
import SelectServer from './SelectServer.jsx';
import ChangeJiraTicketStatus from './ChangeJiraTicketStatus.jsx';

export default function TableMenu(props) {
    return (
        <div className="table-menu">
            <div className="search-and-select-container">
                <input
                    type="search"
                    placeholder="Search source"
                    value={props.searchValue}
                    className="search-field"
                    onChange={(e) => props.setSearchValue(e.target.value)}
                />
                {(props.sourceFilter !== "all") && (
                    <SelectSourceType
                        filter={props.filter}
                        handleFilterChange={props.handleFilterChange} />
                )}
                <SelectJiraTask
                    sourceFilter={props.sourceFilter}
                    handleSourceFilterChange={props.handleSourceFilterChange} />
                {/*{(props.sourceFilter !== "all" && props.sourceFilter !== "missingPrices" && props.sourceFilter !== "missingEstimates" && props.sourceFilter !== "missingPhotos") && (*/}
                {/*    <SelectServer*/}
                {/*        serverFilter={props.serverFilter}*/}
                {/*        handleServerChange={props.handleServerChange} />*/}
                {/*)}*/}
                {/*{(props.sourceFilter !== "all" && props.sourceFilter !== "missingPrices" && props.sourceFilter !== "missingEstimates" && props.sourceFilter !== "missingPhotos") && (*/}
                {/*    <ChangeJiraTicketStatus*/}
                {/*        filter={props.statusFilter}*/}
                {/*        handleFilterChange={props.handleStatusFilterChange} />*/}
                {/*)}*/}
            </div>
        </div>
    )
}