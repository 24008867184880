import React from 'react';
import Select from 'react-select';

const options = [
    { value: 'mine', label: 'Mine' },
    { value: 'all', label: 'All' },
    { value: 'missingPrices', label: 'Missing Prices' },
    { value: 'missingEstimates', label: 'Missing Estimates' },
    { value: 'missingPhotos', label: 'Missing Photos' },
];

export default function SelectJiraTask({ sourceFilter, handleSourceFilterChange }) {
    return (
        <Select
            className="select-source-type"
            value={options.find(option => option.value === sourceFilter)}
            onChange={selectedOption => handleSourceFilterChange(selectedOption.value)}
            options={options}/>
    );
}