import AuctionTableHeader from './AuctionTableHeader.jsx';
import AuctionTableRow from './AuctionTableRow.jsx';

export default function AuctionTable({
    filteredAuctions,
    page,
    rowsPerPage,
    handleRequestSort,
    handleTitleClick,
    auctionsStyles,
    handleDelete,
    handlePublish,
    handleRecover,
    handleSkipEstimates,
    handleSkipPrices,
    handleEdit
}) {
    return (
        <table className="auctions-data-table">
            <AuctionTableHeader handleRequestSort={handleRequestSort} />
            <tbody>
                {filteredAuctions
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((auction) => (
                        <AuctionTableRow
                            key={auction.id}
                            item={auction}
                            handleTitleClick={handleTitleClick}
                            auctionsStyles={auctionsStyles}
                            handleDelete={handleDelete}
                            handlePublish={handlePublish}
                            handleRecover={handleRecover}
                            handleSkipEstimates={handleSkipEstimates}
                            handleSkipPrices={handleSkipPrices}
                            handleEdit={handleEdit}
                        />
                    ))
                }
            </tbody>
        </table>
    );
}