import React from 'react';
import { useLocation } from 'react-router-dom';
import 'x-frame-bypass-enhance';
import './styles/Jewelries.css';
import Header from '../Header/Header';
import Carousel from './Carousel.jsx';

export default function Jewelries() {
    const location = useLocation();
    const sourceId = localStorage.getItem("sourceId");
    const auctionId = localStorage.getItem("auctionId");
    const { filteredAuctions, auctionsStyles } = location.state || { filteredAuctions: [], auctionsStyles: {} };

    return (
        <>
            <Header />
            <div style={{ backgroundColor: "#f8f9f9", padding: 20 }}>
                <Carousel sourceId={sourceId} id={auctionId} auctions={filteredAuctions} auctionsStyles={auctionsStyles} />
            </div>
        </>
    );
};