import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../Header/Header.jsx';
import JewelryCard from './JewelryCard';
import Pagination from './Pagination';
import SearchFilter from './SearchFilter';
import './styles/Production.css';

export default function Production() {
    const location = useLocation();
    const navigate = useNavigate();

    const [currentPageName, setCurrentPageName] = useState(() => {
        const params = new URLSearchParams(location.search);
        return params.get('status') || 'Upcoming';
    });

    const pageSize = 30;

    const [currentPageIndex, setCurrentPageIndex] = useState(() => {
        const params = new URLSearchParams(location.search);
        return Number(params.get('page')) || 1;
    });

    const [totalItems, setTotalItems] = useState(1);
    const totalPages = Math.ceil(totalItems / pageSize);

    const [isLoadingLine, setIsLoadingLine] = useState(false);
    const [jewelries, setJewelries] = useState([]);

    const token = localStorage.getItem('tokenKey');

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        params.set('status', currentPageName);
        params.set('page', currentPageIndex);

        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }, [currentPageName, currentPageIndex, location.pathname, navigate]);

    const fetchJewelries = async () => {
        const queryParams = [];
        queryParams.push(`?status=${encodeURIComponent(currentPageName)}`);

        setIsLoadingLine(true);

        const url = `${process.env.REACT_APP_BASE_URL}/admin/prod/jewelries/${currentPageIndex}/${pageSize}${queryParams.join('&')}`;
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setJewelries(response.data);
        } finally {
            setIsLoadingLine(false);
        }
    };

    useEffect(() => {
        fetchJewelries();
    }, [currentPageIndex, currentPageName, location.search]);

    const fetchCounts = useCallback(async () => {
        let url = `${process.env.REACT_APP_BASE_URL}/admin/prod/jewelries/count?status=${currentPageName}`;
        try {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTotalItems(response.data);
        } catch (error) {
            console.error('Error fetching counts:', error);
        }
    }, [currentPageName, token]);

    useEffect(() => {
        fetchCounts();
    }, [fetchCounts]);

    return (
        <>
            <Header />
            <div className="container">
                <div className="additional-container">
                    <div className="left-panel">
                        <SearchFilter
                            selectedOption={currentPageName}
                            setSelectedOption={setCurrentPageName}
                        />
                    </div>
                    <div className="right-panel">
                        <div className="jewelries-grid">
                            {jewelries.map(jewelry => (
                                <JewelryCard
                                    currentPageName={currentPageName}
                                    key={jewelry.id}
                                    id={jewelry.id}
                                    title={jewelry.title}
                                    photo={jewelry.mainPhoto}
                                    estimatePrice={jewelry.estimatePrice}
                                    currency={jewelry.currency}
                                    sourceName={jewelry.sourceName}
                                    auctionDate={jewelry.startAuctionDate}
                                    initialFavorite={jewelry.isFavorite}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Pagination
                currentPage={currentPageIndex}
                setPage={setCurrentPageIndex}
                totalPages={totalPages}
                pageSize={pageSize}
                setPageSize={() => { }}
            />
        </>
    );
}