import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DetailItem from '../Jewelry/DetailItem.jsx';
import CategoryDetailItem from '../Jewelry/CategoryDetailItem.jsx';
import GenderDetailItem from '../Jewelry/GenderDetailItem.jsx';
import ManufacturerDetailItem from '../Jewelry/ManufacturerDetailItem.jsx';
import Header from '../Header/Header.jsx';
import '../Jewelry/styles/JewelryItemCard.css';
import Frame from '../Jewelry/Frame.jsx';

export default function JewelryItemCard() {
    const { id } = useParams();
    const [jewelryItem, setJewelryItem] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [photo, setPhoto] = useState('');

    useEffect(() => {
        async function fetchJewelryData() {
            const token = localStorage.getItem('tokenKey');
            setIsLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/admin/prod/jewelries/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                setJewelryItem(response.data);
                setPhoto(response.data.photos ? response.data.photos[0] : '');
            } catch (err) {
                console.error('Error fetching data:', err);
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        }
        fetchJewelryData();
    }, [id]);

    const handleChange = (name, value) => {
        setJewelryItem(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const updateJewelryItem = async () => {
        const token = localStorage.getItem('tokenKey');

        const updatePayload = {
            Id: jewelryItem.id,
            Title: jewelryItem.title,
            Price: parseFloat(jewelryItem.price) || 0, // Ensure price is a double
            EstimatePrice: jewelryItem.estimatePrice,
            Dimensions: jewelryItem.dimensions || null,
            Description: jewelryItem.description || null,
            Condition: jewelryItem.condition || null,
            Provenance: jewelryItem.provenance || null,
            Created: jewelryItem.created || null,
            Category: jewelryItem.category || null,
            Gemstone: jewelryItem.gemstone || null,
            DiamondColor: jewelryItem.diamondColor || null,
            GrossWeight: jewelryItem.grossWeight || null,
            StoneWeight: jewelryItem.stoneWeight || null,
            Material: jewelryItem.material || null,
            Gender: jewelryItem.gender || null,
            Manufacturer: jewelryItem.manufacturer || null,
            ClaspOrBuckle: jewelryItem.claspOrBuckle || null,
            Clarity: jewelryItem.clarity || null,
            Weave: jewelryItem.weave || null,
            Motif: jewelryItem.motif || null,
            Style: jewelryItem.style || null,
            Type: jewelryItem.type || null,
            ReferenceNumber: jewelryItem.referenceNumber || null
        };
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}/admin/prod/jewelries`, updatePayload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        } catch (err) {
            console.error('Error updating jewelry item:', err);
            alert("Error updating jewelry item.");
        }
    };

    if (error) {
        return <div className="error-message">Error: {error}</div>;
    }

    if (isLoading) {
        return <div>Loading data...</div>;
    }

    const {
        sourceName, jewelryId, auctionDate, title, url, estimatePrice, price, dimensions,
        description, condition, provenance, created, photos, category, gemstone, grossWeight, stoneWeight,
        material, diamondColor, gender, manufacturer, claspOrBuckle, clarity, weave, motif,
        style, type, currency, referenceNumber
    } = jewelryItem;

    return (
        <>
            <Header/>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
            <div className="main-container">
                <div className="jewelry-main">
                    <div className="jewelry-info-main">
                        <div className="detail-item">
                            <span className="detail-label">Source Name:</span>
                            <label className="detail-value">{sourceName}</label>
                            </div>
                            <div className="detail-item">
                                <span className="detail-label">Auction Date:</span>
                                <label className="detail-value">{auctionDate}</label>
                            </div>
                        <div className="detail-item">
                            <span className="detail-label">Lot number:</span>
                            <label className="detail-value">{jewelryId}</label>
                        </div>
                            <div className="detail-item">
                                <span className="detail-label">Id:</span>
                                <label className="detail-value">{id}</label>
                            </div>

                        <DetailItem
                            label="Title"
                            value={title || ''}
                            onValueChange={(e) => handleChange('title', e.target.value)} />
                        <CategoryDetailItem
                            label="Category"
                            value={category || ''}
                            onValueChange={(e) => handleChange('category', e.target.value)} />
                        <DetailItem
                            label="Type"
                            value={type || ''}
                            onValueChange={(e) => handleChange('type', e.target.value)} />
                        <ManufacturerDetailItem
                            label="Manufacturer"
                            value={manufacturer || ''}
                            onValueChange={(e) => handleChange('manufacturer', e.target.value)} />
                        <DetailItem
                            label="Clarity"
                            value={clarity || ''}
                            onValueChange={(e) => handleChange('clarity', e.target.value)} />
                        <DetailItem
                            label="Diamond color"
                            value={diamondColor || ''}
                            onValueChange={(e) => handleChange('diamondColor', e.target.value)} />
                        <DetailItem
                            label="Buckle"
                            value={claspOrBuckle || ''}
                            onValueChange={(e) => handleChange('claspOrBuckle', e.target.value)} />
                        <DetailItem
                            label="Gemstone"
                            value={gemstone || ''}
                            onValueChange={(e) => handleChange('gemstone', e.target.value)} />
                        <DetailItem
                            label="Material"
                            value={material || ''}
                            onValueChange={(e) => handleChange('material', e.target.value)} />
                        <DetailItem
                            label="Weave"
                            value={weave || ''}
                            onValueChange={(e) => handleChange('weave', e.target.value)} />
                        <DetailItem
                            label="Motif"
                            value={motif || ''}
                            onValueChange={(e) => handleChange('motif', e.target.value)} />
                        <DetailItem
                            label="Style"
                            value={style || ''}
                            onValueChange={(e) => handleChange('style', e.target.value)} />
                        <DetailItem
                            label="Ref"
                            value={referenceNumber || ''}
                            onValueChange={(e) => handleChange('referenceNumber', e.target.value)} />
                        <DetailItem
                            label="Created"
                            value={created || ''}
                            onValueChange={(e) => handleChange('created', e.target.value)} />
                        <DetailItem
                            label="Dimensions"
                            value={dimensions || ''}
                            onValueChange={(e) => handleChange('dimensions', e.target.value)} />
                        <GenderDetailItem
                            label="Gender"
                            value={gender || ''}
                            onValueChange={(e) => handleChange('gender', e.target.value)} />
                        <DetailItem
                            label="Gross"
                            value={grossWeight || ''}
                            onValueChange={(e) => handleChange('grossWeight', e.target.value)} />
                        <DetailItem
                            label="Stone"
                            value={stoneWeight || ''}
                            onValueChange={(e) => handleChange('stoneWeight', e.target.value)} />
                        <DetailItem
                            label="Condition"
                            value={condition || ''}
                            onValueChange={(e) => handleChange('condition', e.target.value)} />
                        <DetailItem
                            label="Provenance"
                            value={provenance || ''}
                            onValueChange={(e) => handleChange('provenance', e.target.value)} />
                        <DetailItem
                            label="Estimate Price"
                            value={estimatePrice || ''}
                            onValueChange={(e) => handleChange('estimatePrice', e.target.value)} />
                        <DetailItem
                            label="Price"
                            value={price || ''}
                            onValueChange={(e) => handleChange('price', e.target.value)} />
                        <div style={{ alignItems: 'start', marginBottom: '0', paddingBottom: '0' }} className="detail-item">
                            <span className="detail-label">Descrip:</span>
                            <textarea
                                style={{ fontFamily: 'Arial, sans-serif' }}
                                value={description || ''}
                                onChange={(e) => handleChange('description', e.target.value)}
                            />
                        </div>

                        <div className="update-button-container">
                            <button type="button" className="fix-button" onClick={() => updateJewelryItem()}>Update</button>
                        </div>
                    </div>
                </div>
                <Frame item={url || ''} />
            </div>
            </div>
        </>
    );
}