import React from 'react';

export default function LogOut() {
    const handleLogOut = () => {
        localStorage.removeItem('tokenKey');
        localStorage.removeItem('email');
        localStorage.removeItem('roleId');
        window.location.href = '/';
    };

    return (
        <button className="logout-button" onClick={handleLogOut}>
            Log Out
        </button>
    );
}