export default function AuctionTableHeader({ handleRequestSort }) {
    return (
        <thead>
            <tr>
                <th className="dark-header">Source Id</th>
                <th className="dark-header">
                    <span onClick={() => handleRequestSort('id')} style={{ color: 'black' }}>Id</span>
                </th>
                <th className="dark-header" style={{ textAlign: 'start' }}>
                    <span onClick={() => handleRequestSort('title')} style={{ color: 'black' }}>Name</span>
                </th>
                <th className="dark-header">
                    <span onClick={() => handleRequestSort('date')} style={{ color: 'black' }}>Start - <br />EndDate</span>
                </th>
                <th className="dark-header" style={{ textAlign: 'start' }}>Event Moderation<br />Status</th>
                <th className="dark-header">Event Status</th>
                <th className="dark-header">Status</th>
                <th className="dark-header">Publishable <br />Lots</th>
                <th className="dark-header">
                    <span onClick={() => handleRequestSort('jewelriesQuantity')} style={{ color: 'black' }}>Total Lots</span>
                </th>
                <th className="dark-header" >Actions</th>
            </tr>
        </thead>
    );
}