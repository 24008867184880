import React from 'react';
import './styles/LoginForm.css';

export default function LoginForm(props) {
    return (
        <div className="login-container">
            <form className="login-form" onSubmit={props.handleSubmit}>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Email"
                        name="name"
                        value={props.email}
                        onChange={props.handleNameChange}
                        required />
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        placeholder="Password"
                        name="password"
                        value={props.password}
                        onChange={props.handlePasswordChange}
                        required />
                </div>
                <button className="login-button" type="submit">Login</button>
            </form>
        </div>
    );
};