import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, AutoSizer } from 'react-virtualized';

export default function ListViewComponent(props) {
    const [query, setQuery] = useState('');
    const [items, setItems] = useState([]);
    const [prevQuery, setPrevQuery] = useState('');
    const [isWarningShown, setIsWarningShown] = useState(false);
    const [filteredItems, setFilteredItems] = useState({ ids: [], colors: [], names: [] });
    const navigate = useNavigate();
    const listRef = useRef();
    const accessToken = localStorage.getItem('tokenKey');
    const currentUser = localStorage.getItem('email').toLowerCase();
    const [selectedColor, setSelectedColor] = useState('');

    let colorLegend = [
        { color: '#1F75FE', name: 'Gennady' },
        { color: 'red', name: 'Igor' },
        { color: 'green', name: 'Yuriy' },
        { color: 'purple', name: 'Anton' }
    ];

    const currentUserData = colorLegend.find(legend => currentUser.includes(legend.name.toLowerCase()));
    if (currentUserData) {
        colorLegend = colorLegend.map(legend => {
            if (legend.name.toLowerCase() === currentUserData.name.toLowerCase()) {
                return { ...legend, name: 'You' };
            }
            return legend;
        });
    }

    useEffect(() => {
        if (props.items) {
            setItems(props.items.map(item => item.name));
        }
    }, [props.items]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.recomputeRowHeights();
        }
    }, [items]);

    useEffect(() => {
        if (props.items) {
            const searchQuery = query.toLowerCase();
            let filteredItems = props.items.filter(item =>
                item.name.toLowerCase().includes(searchQuery) &&
                (selectedColor === '' || item.color === selectedColor)
            );

            const ids = filteredItems.map(item => item.id);
            const names = filteredItems.map(item => item.name);
            const colors = filteredItems.map(item => item.color);

            setFilteredItems({ ids, names, colors });
        }
    }, [props.items, query, selectedColor]);

    const rowRenderer = ({ key, index, style }) => {
        const name = filteredItems.names[index];
        const color = filteredItems.colors[index];
        const id = filteredItems.ids[index];

        const nameStyle = { color: color };
        return (
            <div key={key} style={style} className="list-item">
                <span className="list-item-name" style={nameStyle}>{name}</span>
                <button onClick={() => handleDeleteItem(id)} className="delete-button">&times;</button>
            </div>
        );
    };

    const handleInputChange = (e) => {
        let value = e.target.value;

        if (!props.endpoint.includes('manufacturer')) {
            value = value.replace(/ /g, '\\s');

            if (value.length - prevQuery.length > 1) {
                value = value.replace(/\d+/g, (match) => {
                    return match.length > 2 ? '\\d{1,3}' : '\\d{1,2}';
                });
            } else if (value.length > prevQuery.length) {
                if (value.match(/\\d$/) && !value.match(/\\d\{1,2\}$/) && !value.match(/\\d\{1,3\}$/)) {
                    value = value.replace(/\\d$/, '\\d{1,2}');
                }
            } else if (value.length < prevQuery.length) {
                const regex = /\\(s|d\{1,[23]\})$/;
                if (regex.test(prevQuery)) {
                    value = prevQuery.replace(regex, '');
                }
            }
        }

        setPrevQuery(value);
        setQuery(value);
    };

    const addNewItem = () => {
        if (!query.trim()) {
            setIsWarningShown(true);
            setTimeout(() => setIsWarningShown(false), 1500);
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name: query, color: currentUserData.color })
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/admin/${props.endpoint}`, requestOptions)
            .then(response => {
                if(response.status === 401) {
                    navigate('/login');
                    return {};
                }
                const newId = Math.max(0, ...filteredItems.ids) + 1;
                setItems([...items, query]);
                setFilteredItems(prevState => ({
                    ids: [...prevState.ids, newId],
                    names: [...prevState.names, query],
                    colors: [...prevState.colors, currentUserData.color]
                }));

                setPrevQuery('');
                setQuery('');
            })
            .catch(error => {
                console.error('Error adding item:', error);
                setIsWarningShown(true);
                setTimeout(() => setIsWarningShown(false), 1500);
            });
    };

    const handleDeleteItem = (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/admin/${props.endpoint}/${id}`, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP status ${response.status}`);
                }
                const updatedFilteredItems = {
                    ids: filteredItems.ids.filter(itemId => itemId !== id),
                    names: filteredItems.names.filter((_, index) => filteredItems.ids[index] !== id),
                    colors: filteredItems.colors.filter((_, index) => filteredItems.ids[index] !== id),
                };

                setFilteredItems(updatedFilteredItems);
                setItems(updatedFilteredItems.names);
            })
            .catch(error => {
                console.error('Error deleting item:', error);
            });
    };

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    return (
        <div className="list-view">
            <div className="color-legend">
                {colorLegend.map((legendItem, index) => (
                    <span
                        key={index}
                        className="color-text"
                        style={{
                            color: legendItem.color,
                            marginRight: '5px',
                            cursor: 'pointer',
                            textDecoration: selectedColor === legendItem.color ? 'underline' : 'none'
                        }}
                        onClick={() => handleColorClick(selectedColor === legendItem.color ? '' : legendItem.color)}
                    >
                        {legendItem.name}
                    </span>
                ))}
            </div>
            <div className="search-bar">
                <input
                    className={`search-input ${isWarningShown ? 'input-warning blink' : ''}`}
                    type="text"
                    value={query}
                    onChange={handleInputChange}
                    placeholder="Search for item" />
                <button onClick={addNewItem} className="add-button">Add</button>
            </div>
            <AutoSizer>
                {({ height, width }) => (
                    <List
                        ref={listRef}
                        width={width}
                        height={height}
                        rowCount={filteredItems.names.length}
                        rowHeight={30}
                        rowRenderer={rowRenderer}
                        overscanRowCount={3} />
                )}
            </AutoSizer>
        </div>
    );
};