import React from 'react';

export default function SourceStatusIndicator({ countPublishedAuctions, countPendingAuctions, countDeletedAuctions }) {
    const total = countPublishedAuctions + countPendingAuctions + countDeletedAuctions;

    const publishedWidth = total > 0 ? (countPublishedAuctions / total) * 100 : 0;
    const pendingWidth = total > 0 ? (countPendingAuctions / total) * 100 : 0;
    const deletedWidth = total > 0 ? (countDeletedAuctions / total) * 100 : 0;

    return (
        <div style={{ display: 'flex', width: '100%', height: '100%', position: 'relative', backgroundColor: '#f76262' }}>
            <div style={{ width: `${publishedWidth}%`, backgroundColor: '#66cdaa', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {publishedWidth > 0 ? countPublishedAuctions : ''}
            </div>
            <div style={{ width: `${pendingWidth}%`, backgroundColor: '#FFBF00', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {pendingWidth > 0 ? countPendingAuctions : ''}
            </div>
            <div style={{ width: `${deletedWidth}%`, backgroundColor: '#f76262', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {deletedWidth > 0 ? countDeletedAuctions : ''}
            </div>
        </div>
    );
}