import React from 'react';
import './styles/Dialog.css';

export default function DeleteAuctionDialog(props) {
    if (!props.showDialog) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-dialog">
                <div className="modal-header">
                    <p>Delete Auction</p>
                </div>
                <div className="dialog-modal-content">
                    <p>
                        Are you sure you want to delete the auction with ID {props.item.id} and title {props.item.title}?
                    </p>
                </div>
                <div className="modal-footer">
                    <button onClick={props.handleCancel} className="EditAuctionButton">Cancel</button>
                    <button onClick={props.handleConfirmDelete} className="ActionButton DeleteButton">Confirm delete</button>
                </div>
            </div>
        </div>
    );
}