const DetailItem = ({
    label,
    value,
    fixValue,
    onValueChange,
    onFixValueChange,
    onAddClick
}) => {
    return (
        <div className="detail-item">
            <span className="detail-label">{label}:</span>
            <input
                className="detail-value"
                value={value}
                onChange={onValueChange}
            />
            {onAddClick && (
                <button type="button" className="add-button" onClick={onAddClick}>Add</button>
            )}

            {/*<span className="jewelry-detail-label" style={{ paddingLeft: '0px', marginLeft: '0px' }}>Fix:</span>*/}
            {/*<div className="fix-input-container">*/}
            {/*    <input*/}
            {/*        className="fix-input"*/}
            {/*        style={{*/}
            {/*            color: fixValue === '' ? 'black' :*/}
            {/*                fixValue === value ? 'chartreuse' : 'red',*/}
            {/*            fontWeight: 'bold'*/}
            {/*        }}*/}
            {/*        value={fixValue}*/}
            {/*        onChange={onFixValueChange}*/}
            {/*    />*/}
            {/*</div>*/}
        </div>
    );
};

export default DetailItem;