import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Header/Header.jsx';
import TabsComponent from './TabsComponent.jsx';
import './styles/ModerationView.css';

export default function ModerationView() {
    const accessToken = localStorage.getItem('tokenKey');
    const navigate = useNavigate();
    const [motifs, setMotifs] = useState([]);
    const [styles, setStyles] = useState([]);
    const [weaves, setWeaves] = useState([]);
    const [necklaces, setNecklaces] = useState([]);
    const [chains, setChains] = useState([]);
    const [rings, setRings] = useState([]);
    const [earrings, setEarrings] = useState([]);
    const [bracelets, setBracelets] = useState([]);
    const [brooches, setBrooches] = useState([]);
    const [cufflinks, setCufflinks] = useState([]);
    const [pendants, setPendants] = useState([]);
    const [bangles, setBangles] = useState([]);
    const [claspOrBuckles, setClaspOrBuckles] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [gemstones, setGemstones] = useState([]);
    const [weakMaterials, setWeakMaterials] = useState([]);
    const [strongMaterials, setStrongMaterials] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        if (!accessToken) {
            navigate('/login');
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        };

        const fetchResource = (url) => {
            return fetch(url, requestOptions)
                .then(response => {
                    if (response.status === 401) {
                        throw new Error('Unauthorized');
                    }
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                });
        };

        Promise.all([
            'motifs', 'styles', 'weaves', 'types/necklace', 'types/chain', 'types/ring',
            'types/earrings', 'types/bracelet', 'types/brooch', 'types/cufflinks', 'types/pendant',
            'types/bangle', 'claspOrBuckles', 'conditions', 'gemstones', 'weakMaterials',
            'strongMaterials', 'manufacturers'
        ].map(endpoint => fetchResource(`${process.env.REACT_APP_BASE_URL}/admin/${endpoint}`)))
            .then(allData => {
                setMotifs(allData[0]);
                setStyles(allData[1]);
                setWeaves(allData[2]);
                setNecklaces(allData[3]);
                setChains(allData[4]);
                setRings(allData[5]);
                setEarrings(allData[6]);
                setBracelets(allData[7]);
                setBrooches(allData[8]);
                setCufflinks(allData[9]);
                setPendants(allData[10]);
                setBangles(allData[11]);
                setClaspOrBuckles(allData[12]);
                setConditions(allData[13]);
                setGemstones(allData[14]);
                setWeakMaterials(allData[15]);
                setStrongMaterials(allData[16]);
                setManufacturers(allData[17]);
                setDataLoaded(true);
            })
            .catch(error => {
                if (error.message === "Unauthorized") {
                    navigate('/login');
                } else {
                    console.error('Error fetching data:', error);
                }
            });
    }, [navigate, accessToken]);

    return (
         <>
            <Header />
            <div className="App">
                <TabsComponent
                    motifs={motifs}
                    dataLoaded={dataLoaded}
                    styles={styles}
                    weaves={weaves}
                    necklaces={necklaces}
                    chains={chains}
                    rings={rings}
                    earrings={earrings}
                    bracelets={bracelets}
                    brooches={brooches}
                    cufflinks={cufflinks}
                    pendants={pendants}
                    bangles={bangles}
                    claspOrBuckles={claspOrBuckles}
                    weakMaterials={weakMaterials}
                    strongMaterials={strongMaterials}
                    conditions={conditions}
                    manufacturers={manufacturers}
                    gemstones={gemstones} />
            </div>
        </>
    );
};