import React from 'react';
import { Link } from 'react-router-dom';
import './styles/JewelryCard.css';

export default function JewelryCard({
    currentPageName,
    id,
    title,
    photo,
    estimatePrice,
    price,
    currency,
    sourceName,
    auctionDate,
}) {
    const formattedDate = auctionDate ? auctionDate.split('T')[0] : '';

    return (
        <>
            <Link to={`/jewelry/${id}`} className="jewelry-card-link">
                <div className="jewelry-card">
                    <div className="jewelry-image">
                        <img src={photo || ''} alt={title} />
                    </div>
                    <div className="jewelry-details">
                        <p className="jewelry-title">{title}</p>
                        <p>
                            {currentPageName === "Upcoming" ? (
                                <>
                                    Estimate: {estimatePrice} {currency}
                                </>
                            ) : (
                                <>
                                    Price: {price} {currency}
                                </>
                            )}
                        </p>
                        <p className="source-name">Source: {sourceName}</p>
                        <p>Date: {formattedDate}</p>
                    </div>
                </div>
            </Link>
        </>
    );
}