import React, { useMemo, useCallback } from 'react';
import Select from 'react-select';
import './styles/AuctionHeader.css';

export default function AuctionHeader({ id, date, dateUntil, auctions, handleAuctionChange, auctionsWithFixes }) {
    const options = useMemo(() => auctions.map((auction) => ({
        value: auction.id,
        label: `${auction.id} - ${auction.title}`,
    })), [auctions]);

    const colorMap = useMemo(() => {
        const newColorMap = {};
        if (Array.isArray(auctionsWithFixes)) {
            auctionsWithFixes.forEach(auction => {
                newColorMap[auction.id] = auction.isFixed ? '#66FF00' : auction.isFixed === false ? '#FF033E' : 'black';
            });
        } else if (typeof auctionsWithFixes === 'object') {
            Object.keys(auctionsWithFixes).forEach(key => {
                newColorMap[key] = auctionsWithFixes[key]?.color || 'black';
            });
        }
        return newColorMap;
    }, [auctionsWithFixes]);

    const customStyles = {
        option: (provided, { data }) => {
            return {
                ...provided,
                color: colorMap[data.value] || 'black',
            };
        },
        singleValue: (provided, state) => {
            const color = colorMap[state.data.value] || 'black';
            return { ...provided, color };
        }
    };

    const handleChange = useCallback((selectedOption) => {
        const auctionData = auctions.find((auction) => auction.id === selectedOption.value);
        if (auctionData) {
            handleAuctionChange(auctionData);
        }
    }, [auctions, handleAuctionChange]);

    const currentAuction = useMemo(() => options.find(option => option.value === id) || null, [id, options]);
    const formattedDate = useMemo(() => {
        return date ? `${date.split('T')[0]} → ${dateUntil.split('T')[0]}` : <span className="date-null">Date is NULL</span>;
    }, [date, dateUntil]);

    return (
        <div className="auction-header">
            <h1>
                <Select
                    value={currentAuction}
                    onChange={handleChange}
                    options={options}
                    className="custom-select"
                    styles={customStyles} />
            </h1>
            {formattedDate}
        </div>
    );
};