import React, { useState, useEffect } from 'react';

const manufacturerMap = {
    "tiffany": "Tiffany & Co.",
    "jacob & co": "Jacob & Co.",
    "bulgari": "Bvlgari",
    "holth": "Ivar T. Holth",
    "seman schepps": "Seaman Schepps",
    "fred paris": "Fred",
    "van cleef": "Van Cleef & Arpels",
    "george lenfant": "Georges Lenfant",
    "georges l'enfant": "Georges Lenfant",
    "kieselstein": "B. Kieselstein Cord",
    "murrle": "Murrle Bennett & Co",
    "garrard": "Garrard & Co.",
    "dragsted": "A. Dragsted",
    "aagaard": "Aagaard",
    "arthus": "Arthus-Bertrand",
    "mappin": "Mappin & Webb",
    "norman": "Norman Grant",
    "westwood": "Vivienne Westwood",
    "zimmerman": "Abel & Zimmerman",
    "holmsen": "Aksel Holmsen",
    "augis": "A. Augis",
    "vahan": "A. Vahan",
    "fred davis": "Fred Davis",
    "marina b": "Marina B.",
    " hammer": "Marius hammer",
    "mikimoto": "Mikimoto",
    "perrin": "O. J. Perrin",
    "sterl": "Sterl�",
    "bent erik": "Bent Eriksen",
    "bent gabrielsen": "Bent Gabrielsen",
    "boucher": "Boucheron",
    "c�line": "Celine",
    "charles horner": "Charles Horner",
    "duggin": "Duggin Shappere & Co.",
    "faberg": "Faberg�",
    "buccellati": "Buccellati",
    "george jensen": "Georg Jensen",
    "georg jensen": "Georg Jensen",
    "goldsmiths": "Goldsmiths & Silversmiths Company",
    "caldwell": "J.E. Caldwell & Co.",
    "a stein": "L�a Stein",
    "lalaounis": "Ilias Lalaounis",
    "theodor": "Theodor Fahrner",
    "dior": "Dior",
    "Laurent": "Yves Saint Laurent",
    "Lillo": "William De Lillo",
    "Winston": "Harry Winston",
    "Drummond": "William Drummond & Co.",
    "Balmain": "Balmain",
    "Torun": "Vivianna Torun B�low-H�be",
    "Antonini": "Antonini Milano",
    "Cropp": "Cropp & Farr",
    "Estee Lauder": "Est�e Lauder",
    "Gay Freres": "Gay Fr�res",
    "Midas": "Midas",
    "Mellerio": "Mellerio dits Meller",
    "UNOAERRE": "UnoAErre",
    "Gennaro": "A. Di Gennaro",
    "Darby": "A H Darby & Sons",
    "Carrera": "Carrera Y Carrara",
    "Nilson": "Creole/Per Nilsson",
    "Nilsson": "Creole/Per Nilsson",
    "Hermes": "Herm�s",
    "Illario": "Carlo Illario E F.illi",
    "Niels Erik": "Niels Erik From",
    "Rennie": "Rennie Mackintosh",
    "Trifari": "Trifari",
};

export default function ManufacturerDetailItem({
    label,
    value,
    onValueChange,
    onAddClick
}) {
    const [suggestion, setSuggestion] = useState("");

    useEffect(() => {
        const lowerValue = value.toLowerCase();
        const foundSuggestion = Object.keys(manufacturerMap).find(key => lowerValue.includes(key));
        if (foundSuggestion) {
            setSuggestion(manufacturerMap[foundSuggestion]);
        } else {
            setSuggestion("No suggestion");
        }
    }, [value]);

    const handleSuggestionClick = () => {
        if (suggestion !== "No suggestion") {
            onValueChange({ target: { value: suggestion } });
        }
    };

    return (
        <div className="detail-item">
            <span className="detail-label">{label}:</span>
            <input
                className="detail-value"
                value={value}
                onChange={onValueChange}
            />
            <button type="button" className="add-button" onClick={onAddClick}>Add</button>
            <span>Maybe?</span>
            <span
                className="suggestion-span"
                onClick={handleSuggestionClick}
            >
                {suggestion}
            </span>
        </div>
    );
}