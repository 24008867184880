import React from 'react';
import { Button } from 'react-bootstrap';
import RowsPerPageDropdown from '../Source/RowsPerPageDropdown.jsx';
import './styles/Pagination.css';

export default function Pagination({ page, rowsPerPage, setPage, filteredAuctionsLength, handleChangeRowsPerPage }) {

    const pageCount = Math.ceil(filteredAuctionsLength / rowsPerPage);

    return (
        <div className="pagination-controls">
            <span>Rows per page:</span>
            <RowsPerPageDropdown value={rowsPerPage} onChange={handleChangeRowsPerPage} />
            <span>
                {1 + page * rowsPerPage}-
                {Math.min((page + 1) * rowsPerPage, filteredAuctionsLength)} of {filteredAuctionsLength}
            </span>
            <Button
                onClick={() => setPage((prev) => Math.max(0, prev - 1))}
                disabled={page === 0}>
                {'<'}
            </Button>
            <Button
                onClick={() => setPage((prev) => Math.min(pageCount - 1, prev + 1))}
                disabled={page >= pageCount - 1}>
                {'>'}
            </Button>
        </div>
    );
}