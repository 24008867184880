import React from 'react';
import CreatableSelect from 'react-select/creatable';

const CategoryDetailItem = ({
    label,
    value,
    fixValue,
    onValueChange,
    onFixValueChange
}) => {

    const options = [
        { label: 'Amulet', value: 'Amulet' },
        { label: 'Bracelet', value: 'Bracelet' },
        { label: 'Bracelet, Pendant', value: 'bracelet, Pendant' },
        { label: 'Brooch', value: 'Brooch' },
        { label: 'Brooch, Pendant', value: 'Brooch, Pendant' },
        { label: 'Brooch, Pendant, Necklace', value: 'Brooch, Pendant, Necklace' },
        { label: 'Chain', value: 'Chain' },
        { label: 'Chain, Necklace', value: 'Chain, Necklace' },
        { label: 'Cufflinks', value: 'Cufflinks' },
        { label: 'Demi Parure', value: 'Demi Parure' },
        { label: 'Earrings', value: 'Earrings' },
        { label: 'Jewelry Set', value: 'Jewelry Set' },
        { label: 'Necklace', value: 'Necklace' },
        { label: 'Necklace, Bracelet', value: 'Necklace, Bracelet' },
        { label: 'Necklace, Brooch', value: 'Necklace, Brooch' },
        { label: 'Necklace, Chain', value: 'Necklace, Chain' },
        { label: 'Necklace, Pendant', value: 'Necklace, Pendant' },
        { label: 'Necklace, Pendant, Brooch', value: 'Necklace, Pendant, Brooch' },
        { label: 'Pendant', value: 'Pendant' },
        { label: 'Pendant, Brooch', value: 'Pendant, Brooch' },
        { label: 'Pendant, Chain', value: 'Pendant, Chain' },
        { label: 'Pendant, Necklace', value: 'Pendant, Necklace' },
        { label: 'Ring', value: 'Ring' },
        { label: 'Ring, Brooch', value: 'Ring, Brooch' },
        { label: 'Ring, Pendant', value: 'Ring, Pendant' },
        { label: 'Ring, Pendant, Necklace', value: 'Ring, Pendant, Necklace' },
        { label: 'Ring, Bracelet', value: 'Ring, Bracelet' },
        { label: 'Diadema', value: 'Diadema' }
    ];

    const handleChange = (selectedOption) => {
        onValueChange({
            target: {
                value: selectedOption ? selectedOption.value : '',
            }
        });
    };

    const getOptionByValue = (valueToFind) => {
        return options.find(option => option.value.toLowerCase() === valueToFind.toLowerCase());
    };

    const valueToUse = value ? getOptionByValue(value) : null;

    return (
        <div className="detail-item">
            <span className="detail-label">{label}:</span>
            <CreatableSelect
                className="category-select"
                isClearable
                onChange={handleChange}
                options={options}
                value={valueToUse}
            />
            <span className="jewelry-detail-label" style={{ paddingLeft: '0px', marginLeft: '0px' }}>Fix:</span>
            <div className="fix-input-container">
                <input
                    className="fix-input"
                    style={{
                        color: fixValue === '' ? 'black' :
                            fixValue === value ? 'chartreuse' : 'red',
                        fontWeight: 'bold'
                    }}
                    value={fixValue}
                    onChange={onFixValueChange}
                />
            </div>
        </div>
    );
};

export default CategoryDetailItem;