import React, { useState, useEffect, useRef, useCallback } from 'react';
import './styles/EventStatus.css';

export default function EventStatus({ auctions, setAuctions, auctionsStyles, onDeletedLotsChange }) {
    const [isPendingChecked, setIsPendingChecked] = useState(true);
    const [isPublishedChecked, setIsPublishedChecked] = useState(false);
    const [isDeletedChecked, setIsDeletedChecked] = useState(false);
    const [isEmptyChecked, setIsEmptyChecked] = useState(false);
    const [isDeletedLotsChecked, setIsDeletedLotsChecked] = useState(false);
    const initialAuctionsRef = useRef(auctions);

    const calculateModerationStatuses = useCallback(() => {
        const moderationStatuses = {
            Moderated: 0,
            InModeration: Object.keys(auctionsStyles).length,
            AwaitModeration: 0,
        };

        initialAuctionsRef.current.forEach(item => {
            if (item.isPublished || item.isDeleted) {
                moderationStatuses.Moderated++;
            } else if (item.status === 'InModeration') {
            } else {
                moderationStatuses.AwaitModeration++;
            }
        });

        return moderationStatuses;
    }, [auctionsStyles]);

    const eventModerationStatuses = calculateModerationStatuses(auctions);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        switch (name) {
            case 'Pending':
                setIsPendingChecked(checked);
                break;
            case 'Published':
                setIsPublishedChecked(checked);
                break;
            case 'Deleted':
                setIsDeletedChecked(checked);
                break;
            case 'Empty':
                setIsEmptyChecked(checked);
                break;
            case 'DeletedLots':
                setIsDeletedLotsChecked(checked);
                if (typeof onDeletedLotsChange === 'function') {
                    onDeletedLotsChange(checked);
                }
                break;
            default:
                break;
        }
    };

    const countStatuses = useCallback(() => {
        const statusCounts = {
            Pending: 0,
            Published: 0,
            Deleted: 0,
            Empty: 0,
            DeletedLots: 0
        };

        initialAuctionsRef.current.forEach(auction => {
            if (auction.isPublished && !auction.isDeleted) {
                statusCounts.Published++;
            } else if (auction.isDeleted) {
                statusCounts.Deleted++;
            } else {
                statusCounts.Pending++;
            }
            if (auction.jewelriesQuantity === 0) {
                statusCounts.Empty++;
            }
            if (auction.isDeleted && auction.jewelriesQuantity > 0) { // Assuming lots are marked deleted based on some criteria
                statusCounts.DeletedLots++;
            }
        });

        return statusCounts;
    }, []);

    const eventStatuses = countStatuses();

    const filterAuctions = useCallback(() => {
        if (!isPendingChecked && !isPublishedChecked && !isDeletedChecked && !isEmptyChecked && !isDeletedLotsChecked) {
            setAuctions(initialAuctionsRef.current);
        } else {
            const filtered = initialAuctionsRef.current.filter(auction => {
                const isPending = isPendingChecked && !auction.isPublished && !auction.isDeleted;
                const isPublished = isPublishedChecked && auction.isPublished && !auction.isDeleted;
                const isDeleted = isDeletedChecked && auction.isDeleted;
                const isEmpty = isEmptyChecked && auction.jewelriesQuantity === 0;
                const isDeletedLots = isDeletedLotsChecked && auction.isDeleted && auction.jewelriesQuantity > 0;

                return isPending || isPublished || isDeleted || isEmpty || isDeletedLots;
            });

            setAuctions(filtered);
        }
    }, [isPendingChecked, isPublishedChecked, isDeletedChecked, isEmptyChecked, isDeletedLotsChecked, setAuctions]);

    useEffect(() => {
        filterAuctions();
    }, [filterAuctions]);

    return (
        <div className="event-status-container">
            <div className="event-status-column">
                <h4 className="event-status-h">Event Status</h4>
                <ul>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                name="Pending"
                                checked={isPendingChecked}
                                onChange={handleCheckboxChange}
                            /> Pending ({eventStatuses.Pending})
                        </label>
                    </li>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                name="Published"
                                checked={isPublishedChecked}
                                onChange={handleCheckboxChange}
                            /> Published ({eventStatuses.Published})
                        </label>
                    </li>
                    <li>
                        <label>
                            <input
                                type="checkbox"
                                name="Deleted"
                                checked={isDeletedChecked}
                                onChange={handleCheckboxChange}
                            /> Deleted ({eventStatuses.Deleted})
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Empty"
                                checked={isEmptyChecked}
                                onChange={handleCheckboxChange}
                            /> Empty ({eventStatuses.Empty})
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="DeletedLots"
                                checked={isDeletedLotsChecked}
                                onChange={handleCheckboxChange}
                            /> Deleted lots ({eventStatuses.DeletedLots})
                        </label>
                    </li>
                </ul>
            </div>
            <div className="event-moderation-status-column">
                <h4 className="event-status-h">Event Moderation Status</h4>
                <ul>
                    <li><label>Moderated ({eventModerationStatuses.Moderated})</label></li>
                    <li><label> InModeration ({eventModerationStatuses.InModeration})</label></li>
                    <li><label>Await Moderation ({eventModerationStatuses.AwaitModeration})</label></li>
                </ul>
            </div>
        </div>
    );
};
